import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FadeIn from 'react-fade-in';

const Index = () => (
    <Layout>
        <SEO title="Art Specialists" />
        <div id="content">
            <div id="slideshow">
                <FadeIn delay="800" >
                    <div id="line-1">Define</div>
                    <div id="line-2">Discover</div>
                    <div id="line-3">Design</div>
                    <div id="line-4">Deliver</div>
                </FadeIn>
            </div>
        </div>

        <script>


        </script>
    </Layout>
)

export default Index;